module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"StreamLex","short_name":"StreamLex","start_url":"/","background_color":"#ffffff","theme_color":"#000000","display":"browser","icon":"src/images/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"1b89c289e0dc22682412fb6ef072a494"},
    },{
      plugin: require('../node_modules/@piwikpro/gatsby-plugin-piwik-pro/gatsby-browser.js'),
      options: {"plugins":[],"pluginEnabled":true,"containerUrl":"https://streamlex.piwik.pro","containerId":"0e4695a8-f024-4a4f-92e4-2f5682f8acf2","nonceString":"123"},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"languages":["en"],"defaultLanguage":"en","i18nextOptions":{"fallbackLng":"en","supportedLngs":["en"],"defaultNS":["lawNavBar","mainPage","articlesPage"],"interpolation":{"escapeValue":false}}},
    },{
      plugin: require('../node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://streamlex.eu","stripQueryString":true},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
